
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "error-403",
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    onMounted(() => {
      if(route.name == '403') {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      }
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });
  },
});
